/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import "swiper/css/pagination";
@import "swiper/css/navigation";

@font-face {
    font-family: "Charles Wright";
    font-style: bold;
    src: url('./assets/fonts/CharlesWright-Bold.ttf');
  }

ion-item.input-item {
    --padding-start: 8px;
    background: var(--ion-color-step-100) !important;
    --highlight-background: none !important;
}

ion-button.no-shadow {
    --box-shadow: none !important;
}

.reg-input {
    --background: yellow;
    background-color: yellow;
    border-style: none !important;
}

.driver-input {
    --background: lightgreen;
    background-color: lightgreen;
    border-style: none !important;
}

.appinfo-input {
    --background: rgb(145, 217, 248);
    background-color: rgb(145, 217, 248);
    border-style: none !important;
}

ion-select {
    width: 100%;
}

p.kiosk-driver-fee-info {
    text-align: center;
    font-size: 1rem;
    margin: 0px;
    padding-top: 6px;
}

div#save-card-element,
div#card-element2, 
div#card-element3
{
    padding: 10px 20px 11px;
    background-color: #f5f5f5;
    border-style: solid;
    border-width: 2px;
    border-color: #67b1ff;
    font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
    font-weight: 600;
}

body[data-theme="dark"]
{
    div#save-card-element,
    div#card-element2,
    div#card-element3
    {
        padding: 10px 20px 11px;
        background-color: #ffffff;
        border-style: solid;
        border-width: 2px;
        border-color: #d7d7d7;
        font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
        font-size: 16px;
        font-weight: 600;
    }
}

ion-input {
    background: var(--ion-color-step-50);
    --padding-start: 12px;
    height: 44px;
    width: 100%;
}

.top-navbar {
    font-size: xx-large;
    font-weight: 500;
    padding-bottom: 20px;
    padding-top: 8px;
}

.custom-outline {
    --background: transparent;
    --ripple-color: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
}
  
.outline-price {
    --border-color: #989AA2;
}

.custom-button {
    min-height: fit-content !important;
    --padding-top: 0;
    --padding-bottom: 0;
    white-space: normal !important;
    line-height: 1.5 !important;
    --inner-padding-end: 4px;
    --padding-start: 4px;
    cursor: pointer;
    --min-height: 56px;
}

.custom-button-label {
    color: #2f2f2f;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0px;
    font-size: 14px;
    white-space: normal !important;
    cursor: pointer;
}

.custom-shadow {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.kiosk-button {
    border-style: solid;
    border-radius: 6px;
    min-height: fit-content !important;
    white-space: normal !important;
    line-height: 1.5 !important;
    --inner-padding-end: 4px;
    --padding-start: 4px;
    --border-width: 0px;
    cursor: pointer;
    --background: var(--ion-color-step-50) !important;
}

.kiosk-button:hover {
    --background: var(--ion-color-step-150) !important;
}

.kbBtn {
    cursor: pointer;
    background: var(--ion-color-step-50);
    border-style: solid;
    border-color: var(--ion-background-color);
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: -webkit-baseline-middle;
}

.kbBtn:hover {
    background: var(--ion-color-step-150);
}

.kbBtnGreen {
    cursor: pointer;
    color: black;
    background: var(--ion-color-success, #2dd36f);
    border-style: solid;
    border-color: var(--ion-background-color);
    border-radius: 6px;
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-family: var(--ion-font-family, inherit);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: -webkit-baseline-middle;
}

.kbBtnGreen:hover {
    background: #55e890;
}

.kbBtnRed {
    cursor: pointer;
    background: var(--ion-color-danger, #eb445a);
    border-style: solid;
    border-color: var(--ion-background-color);
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: -webkit-baseline-middle;
}

.kbBtnRed:hover {
    background: #f66073;
}

.bg-danger {
    --background: #F33F43;
    --background-focused: var(--ion-color-danger-shade, #f13538) !important;
    --background-hover: var(--ion-color-danger-tint, #f35254) !important;
}

.bg-success {
    --background: #28DA66;
    --background-focused: var(--ion-color-success-shade, #28ba62) !important;
    --background-hover: var(--ion-color-success-tint, #42d77d) !important;
}

.bigger-button {
    height: 56px;
}

div.checkout-info-not-expired {
    padding: 12px;
    font-weight: 500;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
}

div.checkout-info-expired {
    padding: 12px;
    font-weight: 500;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
    color: red;
}

div.discount-box {
    text-align: right;
    background-color: var(--ion-color-step-100);
    border-top-style: solid;
    border-top-color: var(--ion-color-step-200);
    border-top-width: 1px;
    margin-top: 8px;
    padding-top: 8px;
    color: rgb(92, 146, 11);
}

.align-center {
    text-align: center !important;
}

.full-screen-modal {
    --width: 100vw !important;
    --height: 100vh !important;
}

p.error-label {
    margin: 0px;
    padding-top: 2px;
    font-size: smaller;
    color: #ea6153;
    text-align: left;
    background-color: white;
    border-top-color: #ea6153;
    border-top-style: solid;
    border-top-width: 1px;
}

ion-toast.toast-error {
    opacity: 0.9;
    text-align: center;
    color: white;
    --background: #ea6153;;
}

ion-toast.toast-info {
    opacity: 0.9;
    text-align: center;
    color: white;
    --background: #7577B2;;
}

ion-toast.toast-success {
    opacity: 0.9;
    text-align: center;
    color: white;
    --background: #32B194;;
}

ion-grid.fixed-width-grid {
    max-width: 800px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    min-height: 100%;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: #f5f5f5;
    border-right-width: 2px;
    border-right-style: solid;
    border-right-color: #f5f5f5;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.outer {
    display: table;
    position: absolute;
    height: 96%;
    width: 100%;
    top: 0;
    left: 0;
    margin: auto;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner-no-min {
    margin-left: 0px;
    margin-right: 0px;
    margin: auto;
}

.inner {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 800px;
    width: 100vw;
    padding: 16px;
    margin: auto;
}

ion-grid.hydrated {
    padding: 0px;
}

ion-item.item.hydrated.item-label {
    --padding-start: 0px !important;
}

ion-select {
    width: 100% !important;
    max-width: 100%;
    --padding-start: 0px !important;
}

.sc-ion-label-ios-h {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/*
.datepicker--nav-title {
    font-size: 22px;
}

.picker-ios .picker-toolbar-button:first-child > button {
    font-size: 16px !important;
    color: grey !important;
    padding-right: 0px;
    overflow: visible;
    white-space: pre;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
    min-width: 300px;
}

.datepicker--time-row
{
    height: 48px !important;
}

.datepicker-inline .datepicker {
    border-color: white !important;
}

.datepicker--cell {
    font-size: 22px;
}

.datepicker
{
    width: 100% !important;
}

nav.datepicker--nav
{
    min-height: 60px;
}

.datepicker--cell {

    min-height: 60px;
}

.datepicker--time.-am-pm- {
    min-height: 80px;
}
*/

.price-button
{
    border-style: solid;
    border-color: #989aa2;
    border-width: 2px;
    border-radius: 6px;
}

/* Larger than a phone */
@media (min-width: 567px) {
    .price-button
    {
        font-size: 20px;
    }
  }
  
  /* Small devices such as phone */
  @media (max-width: 567px) {
    .price-button
    {
        font-size: 13px;
    }
  }

@media only screen and (max-width: 449px) {

    .alert-radio-group {
       height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 400px !important;
    }
}

@media only screen and (min-width: 450px) {

    .sc-ion-alert-md-h
    {
        --max-width: 500px !important;
    }

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 400px !important;
    }
}

@media only screen and (min-width: 700px) {

    .sc-ion-alert-md-h
    {
        --max-width: 500px !important;
    }

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 600px !important;
    }
}

@media only screen and (min-width: 768px) {

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 700px !important;
    }
}
    
@media only screen and (min-width: 850px) {

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 800px !important;
    }
}

@media only screen and (min-width: 900px) {

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 800px !important;
    }
}

@media only screen and (min-width: 1000px) {

    .alert-radio-group {
        max-height: 82% !important;
    }

    .alert-radio-group.sc-ion-alert-ios {
        height: 82%;
    }

    .alert-radio-group.sc-ion-alert-md {
        height: 82% !important;
        max-height: 82% !important;
    }

    .sc-ion-alert-md-h
    {
        --max-width: 800px !important;
    }
}


.swiper-wrapper {
    max-width: 92vw;
}

ion-input {
    --padding-start: 8px !important;
    min-height: 0px !important;
}

ion-select {
  min-height: 0px !important;
}

[hidden] {
  display: none !important;
}